import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

import "./Header.css";

function Header() {
  return (
    <div className="AppHeader">
      <div className="socials">
        <ul>
          <li>
            <a
              href="https://www.facebook.com/ManilvaPride/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
