import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import "./MissionStatement.css";

const statements = [
  {
    lang: "es",
    text: "El orgullo de Manilva comparte el amor &#x2764;&#xFE0F; y no sólo el día de San Valentín. Apunta la fecha del 15 de junio de 2024! Me gusta y seguir para más. ",
  },
  {
    lang: "en",
    text: "Manilva pride is sharing the love &#x2764;&#xFE0F; and not just on Valentine’s day. Save the date 15th June 2024! Like and follow for more.",
  },
  {
    lang: "fr",
    text: "Le Manilva pride partage l'amour &#x2764;&#xFE0F; et pas seulement le jour de la Saint-Valentin. Retenez la date du 15 juin 2024! Suivez-nous pour en savoir plus.",
  },
];

const MissionStatement = () => {
  const [statementIndex, setStatementIndex] = useState(0); // start with the Spanish statement

  useEffect(() => {
    const intervalId = setInterval(() => {
      setStatementIndex(
        (statementIndex) => (statementIndex + 1) % statements.length
      );
    }, 20000);
    return () => clearInterval(intervalId);
  }, []);

  const statement = statements[statementIndex];

  return (
    <div className="mission-statement">
      <p><span className="mission-text" dangerouslySetInnerHTML={{ __html: statement.text }} />
        <a
          href="https://www.facebook.com/ManilvaPride/"
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp; <FontAwesomeIcon icon={faFacebook} className="facebook" />
        </a></p>
    </div>
  );
};

export default MissionStatement;
