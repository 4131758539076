import React from "react";
import "./Footer.css";

import "./Footer.css";

function Footer() {
  return (
    <div className="Footer">
      <div className="columns">
        <div className="column column-1">
          <div className="copyright">
            <small>&copy;2024 Manilva Pride. All rights reserved.</small>
          </div>
        </div>
        <div className="column column-2">
          <img src="/images/sponsors/mnyml.jpg" alt="M.N.Y.M.L" />
          <img
            src="/images/sponsors/extranjeros-residentes.jpg"
            alt="Extranjeros Residentes"
          />
        </div>
        <div className="column column-3">
          <div className="contacts">

            <small>
              <strong>Contact Us</strong><br />
              If you're interested in supporting Manilva Pride by sponsoring us
              or for any other general enquiries please email us at
              team@manilvapride.com or WhatsApp +34 667882403
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
