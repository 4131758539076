import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Analytics } from '@vercel/analytics/react';

// import HomePage from "./pages/HomePage";
// import EventsPage from "./pages/EventsPage";
// import SponsorsPage from "./pages/SponsorsPage";
// import MerchPage from "./pages/MerchPage";
// import GalleryPage from "./pages/GalleryPage";
// import ContactPage from "./pages/ContactPage";

import Header from "./components/Header";
// import Banner from "./components/Banner";
// import Menu from "./components/Menu";
import HoldingPage from "./pages/HoldingPage";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Analytics />
      <Header />
      {/* <Menu /> */}
      {/* <Banner /> */}
      <Router>
        <Routes>
          <Route index element={<HoldingPage />} />
          {/*
          <Route index element={<HomePage />} />
          <Route path="events" element={<EventsPage />} />
          <Route path="sponsors" element={<SponsorsPage />} />
          <Route path="merch" element={<MerchPage />} />
          <Route path="gallery" element={<GalleryPage />} />
          <Route path="contact" element={<ContactPage />} />
          */}
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
