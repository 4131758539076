import React from "react";
import MissionStatement from "../components/MissionStatement";
import "./HoldingPage.css";

function HoldingPage() {
  return (
    <div className="HoldingPage Page">
      <img
        src="/images/PrideLogo1024.png"
        alt="Pride Logo"
        className="pride-logo"
      ></img>
      <span className="gradient year">2024</span>
      <MissionStatement />
    </div>
  );
}

export default HoldingPage;
